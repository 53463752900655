<template>
  <div class="build views">
    <container ref="container"></container>
  </div>
</template>
<script>
import init from '@/mixins/'
export default {
  mixins: [init],
}
</script>
<style lang="scss">
@import "../styles/style.scss";
</style>